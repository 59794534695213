import React from 'react'
import { Link, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Hero from '../components/Hero'

const HomePage = ({ data }) => {
  const pageTitle = 'LAURIE ON TECH Homepage'

  return (
    <Layout>
      <Helmet title={pageTitle} />
      <Hero
        image={data.banner.childImageSharp.gatsbyImageData}
        title="LAURIE BARTH"
        description="Software Engineer, Conference Speaker and Board Game Geek"
      />
      <section id="about" className="special wrapper container style4 post">
        <h2 className="hs h2">ABOUT LAURIE</h2>
        <StaticImage
          className="headshot"
          src="../data/headers/headshot.jpg"
          alt="headshot"
          width={400}
          imgStyle={{ borderRadius: '50%' }}
        />

        <p className="bio p">
          Laurie is a software engineer who started as a mathematician. She
          currently works as a Senior Software Engineer at Netflix.
          Additionally, Laurie is a content creator and technical educator
          across various mediums.
          <br />
          <br />
          Laurie is a frequent conference speaker, speaking at events all over
          the globe. She's also a technical blogger, contributing to
          publications such as CSS Tricks, Smashing Magazine and A List Apart.
          Additionally, Laurie is an active member of the TC39 Educator's
          committee and a Google Developer Expert. In her free time, you'll find
          her
          <Link to="/lego"> building LEGO sets</Link> or playing with her puppy,
          Avett.
          <br />
          <br />
          Looking to sign up for my newsletter? Check out the{' '}
          <Link to="/signup">Sign Up Page</Link>.
          <br />
          <br />
          Looking to get in touch? Please visit the{' '}
          <Link to="/contact"> Contact Page </Link>.
        </p>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "headers/default.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 2.33
          width: 700
          transformOptions: { cropFocus: CENTER }
          webpOptions: { quality: 100 }
          jpgOptions: { quality: 100 }
        )
      }
    }
  }
`
export default HomePage
